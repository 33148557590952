import { Message } from 'element-ui';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => import(/* webpackChunkName: "layout" */ '@/components/index'),
		children: [
			{
				path: '/',
				name: 'login',
				component: () => import(/* webpackChunkName: "login" */ '@/views/login/login'),
			},
			// 菜单页面布局
			{
				path: '/layout',
				name: 'layout',
				component: () => import(/* webpackChunkName: "layout" */ '@/views/layout/layoutIndex.vue'),
				children: [
					// 用户信息
					{
						path: '/user/info',
						name: 'personInfo',
						component: () => import(/* webpackChunkName: "user" */ '@/views/main/personInfo/personInfo'),
					},
					// 用户管理
					{
						path: '/user/manage',
						name: 'UserManagement',
						component: () =>
							import(/* webpackChunkName: "user" */ '@/views/superUser/UserManagement/UserManagementIndex.vue'),
					},
					// 轮播图管理
					{
						path: '/carousel',
						name: 'BannerManagement',
						component: () =>
							import(/* webpackChunkName: "system" */ '@/views/superUser/BannerManagement/BannerManagement.vue'),
					},
					// 系统访问量日志管理
					{
						path: '/sysAccess',
						name: 'SysAccessManagement',
						component: () =>
							import(/* webpackChunkName: "system" */ '@/views/superUser/SysAccessManagement/SysAccessManagement.vue'),
					},
					// 公告管理
					{
						path: '/notice',
						name: 'NoticeManagement',
						component: () =>
							import(/* webpackChunkName: "system" */ '@/views/superUser/NoticeManagement/NoticeManagement.vue'),
					},
					// 体彩管理
					// 体彩数字彩
					{
						path: '/superLotto',
						name: 'superLotto',
						component: () =>
							import(/* webpackChunkName: "sport" */ '@/views/superUser/SportLotteryManagement/LotteryIndex.vue'),
					},
					// 竞彩足球
					{
						path: '/football',
						name: 'football',
						component: () =>
							import(/* webpackChunkName: "football" */ '@/views/superUser/FootballManagement/footballIndex.vue'),
					},
					// 竞彩篮球
					{
						path: '/basketball',
						name: 'basketball',
						component: () =>
							import(/* webpackChunkName: "basketball" */ '@/views/superUser/BasketballManagement/basketballIndex.vue'),
					},
					// 福彩管理
					// 双色球
					{
						path: '/welfare',
						name: 'welfare',
						component: () =>
							import(/* webpackChunkName: "welfare" */ '@/views/superUser/WelfareLotteryManagement/WelfareIndex.vue'),
					},
					// 日志管理
					{
						path: '/syslog',
						name: 'syslog',
						component: () =>
							import(/* webpackChunkName: "system" */ '@/views/superUser/LogManagement/LogManagement.vue'),
					},
					{
						path: '/loggingEventManagement',
						name:'loggingEventManagement',
						component:()=>import(/* webpackChunkName: "system" */ '@/views/superUser/SystemManagement/LoggingEventManagement/LoggingEventManagement.vue')
					},
					// 会员级别管理
					{
						path: '/vipLevel',
						name: 'vipLevel',
						component: () =>
							import(/* webpackChunkName: "system" */ '@/views/superUser/SystemManagement/memberLevel/memberLevel.vue'),
					},
					// 版本管理
					{
						path: '/version',
						name: 'version',
						component: () =>
							import(
								/* webpackChunkName: "system" */ '@/views/superUser/SystemManagement/versionManagement/versionManagemnet.vue'
							),
					},
					// 定时器日志管理
					{
						path: '/scheduled',
						name: 'scheduled',
						component: () =>
							import(
								/* webpackChunkName: "system" */ '@/views/superUser/SystemManagement/scheduledManagement/scheduledManagement.vue'
								),
					},
					// 店主管理
					{
						path: '/shopOwner',
						name: 'shopOwner',
						component: () =>
							import(/* webpackChunkName: "system" */ '@/views/superUser/ShopperManagement/ShopOwnerIndex.vue'),
					},
					// 用户方案管理
					{
						path: '/userPlan',
						name: 'userPlan',
						component: () =>
							import(/* webpackChunkName: "user" */ '@/views/superUser/UserPlanManagement/UserPlanManagement.vue'),
					},
					// 联赛管理
					{
						path: '/league',
						name: 'league',
						component: () =>
							import(/* webpackChunkName: "football" */ '@/views/superUser/LeagueManagement/LeagueIndex.vue'),
					},
					// 关于我们
					{
						path: '/aboutUS',
						name: 'aboutUS',
						component: () => import(/* webpackChunkName: "system" */ '@/views/superUser/aboutUS'),
					},
					//短信管理
					{
						path: '/smsManagement',
						name:'smsManagement',
						component:()=>import(/* webpackChunkName: "system" */ '@/views/superUser/SMSManagement/SMSManagement.vue')
					},
					//图片管理
					{
						path: '/imageManagement',
						name: 'imageManagement',
						component:()=>import(/* webpackChunkName: "system" */ '@/views/superUser/SystemManagement/ImageManagement/ImageManagement.vue')
					}
				],
			},
		],
	},
];

const router = new VueRouter({
	routes,
});
// 路由拦截;
router.beforeEach((to, from, next) => {
	// console.log(to);
	// console.log(from);
	// const roleUrl = JSON.parse(localStorage.getItem('roleUrl'));
	const isLogin = window.localStorage.getItem('satoken');
	const username = window.sessionStorage.getItem('username');
	// if (isLogin && roleUrl) {
	if (isLogin && username) {
		// 如果前往的网页没有权限，就终止此次前进
		// if (!roleUrl.includes(to.path)) {
		//   return next(false);
		// }
		next();
	} else {
		if (to.path === '/') {
			next();
		} else {
			Message('没有访问权限或登陆已过期，请重新登陆');
			next('/');
		}
	}
});

export default router;
